import * as React from "react";
// svg to component conversion with https://react-svgr.com/playground/
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="164mm"
    height="164mm"
    viewBox="0 0 164 164"
    {...props}
  >
    <defs>
      <clipPath clipPathUnits="userSpaceOnUse" id="b">
        <circle
          style={{
            opacity: 1,
            fill: "#fff",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 29.93498993,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
          cx={105.626}
          cy={134.277}
          r={146.82}
        />
      </clipPath>
      <filter
        style={{
          colorInterpolationFilters: "sRGB",
        }}
        id="a"
        x={-0.004}
        width={1.007}
        y={-0.004}
        height={1.007}
      >
        <feGaussianBlur stdDeviation={0.031} />
      </filter>
    </defs>
    <path
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      d="M36.832 270.42c-7.785-.381-14.443-4.077-20.832-8.262 3.597-14.784 18.577-26.525 32-24.731-.86 2.663-4.027 24.676-4.027 24.676s-7.356 2.392-7.14 8.317z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#ee815a",
        fillRule: "nonzero",
      }}
      d="M36.924 270.254c-.054 2.368 1.19 5.329 4.65 8.989.866.915 2.056 14.103 1.028 19.324-25.31-10.658-30.287-23.846-26.447-37.195 6.112 4.522 13.251 7.697 20.769 8.882z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#f6926e",
        fillRule: "nonzero",
      }}
      d="M29.83 290.858c-2.811-2.075-5.237-4.477-7.552-7.044-3.694-4.696-6.23-9.883-7.278-15.671a25.576 25.576 0 0 1 1.103-6.771c6.175 4.641 13.452 7.863 21.116 9.01-.055 2.402 1.213 5.406 4.741 9.119.827.819 2.481 14.743 1.93 21.077-4.245-3.44-9.097-5.897-13.508-9.01a3.51 3.51 0 0 0-.551-.71z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#ee815a",
        fillRule: "nonzero",
      }}
      d="M31.919 301.44c-2.783-3.963-4.227-9.027-1.338-15.466 2.943-6.604 8.4-8.145 14.125-4.788 2.836 1.651 5.083 4.183 7.277 6.714 2.033 2.422 4.12 5.229 4.013 8.42-.107 4.073-1.659 7.486-4.869 9.852-1.766 1.32-7.598 4.513-10.487 4.458-2.087 0-4.173-.275-5.993-1.376-1.337-.77-2.568-1.816-3.263-3.247-.696-1.431-.696-3.247.32-4.458.108 0 .161-.055.215-.11z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#f6926e",
        fillRule: "nonzero",
      }}
      d="M32.592 303.627c0 .055.055.055.055.11.272.33.598.495.925.495 1.034.495 2.123.77 3.483.88 1.415.055 1.85-1.925.708-2.585-1.85-1.1-3.647-2.475-5.17-4.07-.055-.11-.055-.275-.164-.385-.816-1.54-1.633-3.08-2.286-4.62A52.407 52.407 0 0 1 29 289.27c.272-1.045.599-2.145 1.143-3.3 2.993-6.6 8.545-8.141 14.369-4.786 2.884 1.65 5.17 4.18 7.402 6.71 2.068 2.42 4.19 5.226 4.082 8.416-.109 4.07-1.687 7.481-4.953 9.846-1.796 1.32-7.293 4.29-10.668 4.456-2.122.11-4.245-.275-6.096-1.376-1.36-.77-2.612-1.815-3.32-3.245-.544-1.155-.653-2.53-.163-3.685.599.495 1.198.935 1.796 1.32z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#292c33",
        fillRule: "nonzero",
      }}
      d="M142.817 373.8s-.871-58.197-7.569-76.82c-2.341-2.058-89.575 4.06-89.575 4.06l-1.144 72.76H35v7.2h27.281l5.609-3.573V381h11.762s1.198-42.28 10.019-61.5c7.406 8.554 15.138 39.737 17.752 54.3.817 4.439 1.579 7.2 1.579 7.2h12.47v-3.519l5.663 3.519H153v-7.2z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#3e404d",
        fillRule: "nonzero",
      }}
      d="M143 373.963h-35.299c-5.514-34.803-17.46-54.714-17.46-54.714s-8.217 29.13-8.757 54.714h-36.11c-3.676-49.259 1.189-73.315 1.189-73.315l88.923-4.091z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#292c33",
        fillRule: "nonzero",
      }}
      d="M90 319.678c-6.58 21.757-8.883 54.285-8.883 54.285H69s8.554-39.888 21-54.285z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#010101",
        fillRule: "nonzero",
      }}
      d="M81 373.963 79.798 381H68v-2.963l1.584-4.074z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#292c33",
        fillRule: "nonzero",
      }}
      d="m143 373.963-20-77.406h12.363L143 373.963"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#17181c",
        fillRule: "nonzero",
      }}
      d="M81 373.963 79.798 381H68v-2.963l1.584-4.074zM143.027 373.963H153V381h-12v-7.037h2.027"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      d="M65.775 223.174c47.307 2.272 66.305 14.767 66.305 14.767s2.613 8.113 3.593 21.798c-.598 0-1.143.163-1.633.487-2.123 1.569-2.45 4.22-2.014 8.06.599 5.192 2.613 11.575 3.974 15.253-.054 5.626-.109 11.36 0 17.039H44.218L44 254.006c-.054 0 6.805-31.157 21.775-30.832z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#ee815a",
        fillRule: "nonzero",
      }}
      d="m136 300.578-3.726-15.65-5.833-2.517c-10.207-4.377-15.068-16.196-10.963-26.648l12.367-31.627-17.876-1.97-31.107 35.294-12.422-29.986c-11.827 0-21.44 9.74-21.44 21.723v51.326l91 .055z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      d="M132.147 281.752H85.535l37.387-58.58h-8.844l-37.985 58.58-16.008-58.58h-8.899l16.062 58.58H45v8.773h91z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#f9aa36",
        fillRule: "nonzero",
      }}
      d="M136 300.578V277.76s-35.15-8.755-10.524-43.83a7857.98 7857.98 0 0 0-15.676-11.764L78.554 257.46l-10.957-29.603c-11.88 0-23.597 9.357-23.597 21.34v51.326l92 .055z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <g
      style={{
        fill: "#292c33",
        fillRule: "nonzero",
      }}
    >
      <path d="M118.051 140.337h10.246v1.021H118.05z" />
      <path d="M122.48 140.74c1.574-3.82 4.696-6.192 4.726-6.216l-.615-.817c-.138.103-3.375 2.559-5.062 6.642Z" />
      <path d="m120.926 140.62.952-.15c-.512-3.261-1.983-5.904-2.043-6.012l-.844.469c.018.03 1.453 2.606 1.935 5.693Z" />
    </g>
    <path
      style={{
        fill: "#ee815a",
        fillRule: "nonzero",
      }}
      d="M38.39 200.176c13.305 0 24.11-10.816 24.11-24.132s-10.805-24.132-24.11-24.132c-13.303 0-24.109 10.816-24.109 24.132s10.806 24.132 24.11 24.132z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#010101",
        fillRule: "nonzero",
      }}
      d="M22.372 131.747c0 2.011.163 4.077.434 6.142l1.032 7.229h31.658c3.746 0 7.276 2.01 9.177 5.272a10.666 10.666 0 0 0 9.176 5.272h67.442c6.897 0 13.304 3.75 16.67 9.783l22.264-10.49c1.412-7.228 2.172-15.598 2.172-23.153l-160.025-.055z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#ee815a",
        fillRule: "nonzero",
      }}
      d="M138.033 27.556c11.132 5.924 15.802 16.034 18.68 27.339.977 3.859 2.824 7.065 7.33 5.978 4.725-1.141 4.127-5 3.313-8.696-3.367-14.838-14.824-25.001-29.323-24.62z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#ee815a",
        fillRule: "nonzero",
      }}
      d="M10.372 132.834c.325 2.283.76 4.457 1.303 6.523 2.986 10.38 9.394 19.077 17.81 24.947 8.254 5.109 18.952 7.772 32.038 7.555 24.707.597 44.527-14.023 60.98-29.296v-16.849c0-22.12-17.92-40.056-40.02-40.056H3.91v.76zm63.369-28.806c0-5.38 4.398-9.783 9.774-9.783a9.773 9.773 0 0 1 9.774 9.783v7.61c0 5.38-4.398 9.783-9.774 9.783-5.376 0-9.774-4.403-9.774-9.784zM47.188 134.9h25.684c0 7.12-5.756 12.881-12.87 12.881-7.058 0-12.814-5.707-12.814-12.881zm-17.431-30.872c0-5.38 4.398-9.783 9.774-9.783a9.773 9.773 0 0 1 9.774 9.783v7.61c0 5.38-4.398 9.783-9.774 9.783-5.376 0-9.774-4.403-9.774-9.784z"
      transform="matrix(.11082 0 0 .11082 115.705 117.475)"
    />
    <path d="M120.086 127.919c.595 0 1.083.482 1.083 1.084v.843c0 .596-.488 1.084-1.083 1.084a1.087 1.087 0 0 1-1.084-1.084v-.843c0-.596.488-1.084 1.084-1.084zM124.96 127.919c.595 0 1.083.482 1.083 1.084v.843c0 .596-.488 1.084-1.083 1.084a1.087 1.087 0 0 1-1.083-1.084v-.843c0-.596.487-1.084 1.083-1.084zM122.354 133.851a1.42 1.42 0 0 1-1.42-1.427h2.846c0 .789-.638 1.427-1.426 1.427z" />
    <path
      style={{
        fill: "#ee815a",
        fillRule: "nonzero",
      }}
      d="M38.934 10.925 107.788.978c24.652-3.587 48.165 13.969 51.151 39.079 4.562 38.807-12 77.342-36.436 102.67 0-.055-.054-.055-.054-.11l.054-.054v-16.849c0-22.12-17.92-40.056-40.02-40.056H3.91L.543 62.884c-3.53-25.001 13.792-48.427 38.39-51.96Z"
      transform="matrix(.11082 0 0 .11082 115.705 117.475)"
    />
    <path
      style={{
        fill: "#f6926e",
        fillRule: "nonzero",
      }}
      d="M3.91 85.712v.76l6.462 46.362c.325 2.283.76 4.457 1.303 6.523 2.986 10.38 9.394 19.077 17.81 24.947 8.254 5.109 18.952 7.772 32.038 7.555 24.707.597 44.799-12.664 60.98-29.296v-16.849c0-22.12-17.92-40.056-40.02-40.056H3.91Zm69.83 18.316c0-5.38 4.4-9.783 9.775-9.783a9.773 9.773 0 0 1 9.774 9.783v7.61c0 5.38-4.398 9.783-9.774 9.783-5.376 0-9.774-4.403-9.774-9.784zM47.189 134.9h25.684c0 7.12-5.756 12.881-12.87 12.881-7.058 0-12.814-5.707-12.814-12.881zm-17.431-30.872c0-5.38 4.398-9.783 9.774-9.783a9.773 9.773 0 0 1 9.774 9.783v7.61c0 5.38-4.398 9.783-9.774 9.783-5.376 0-9.774-4.403-9.774-9.784z"
      transform="matrix(.11082 0 0 .11082 115.705 117.475)"
    />
    <path
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      d="M60.003 147.781c-7.06 0-12.815-5.707-12.815-12.881h25.684c0 7.12-5.756 12.881-12.87 12.881z"
      transform="matrix(.11082 0 0 .11082 115.705 117.475)"
    />
    <path
      style={{
        fill: "#292c33",
        fillRule: "nonzero",
      }}
      d="M39.531 94.245a9.773 9.773 0 0 1 9.774 9.783v7.61c0 5.38-4.398 9.783-9.774 9.783-5.376 0-9.774-4.403-9.774-9.784v-7.609c0-5.38 4.398-9.783 9.774-9.783zM83.515 94.245a9.773 9.773 0 0 1 9.774 9.783v7.61c0 5.38-4.398 9.783-9.774 9.783-5.376 0-9.774-4.403-9.774-9.784v-7.609c0-5.38 4.398-9.783 9.774-9.783z"
      transform="matrix(.11082 0 0 .11082 115.705 117.475)"
    />
    <path
      style={{
        fill: "#e39955",
        fillRule: "nonzero",
      }}
      d="M22.318 132.02v.706c.054-.218 0-.49 0-.707z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#e6c00c",
        fillRule: "nonzero",
      }}
      d="M201.566 129.03h.108c0-4.729-3.204-8.805-7.44-10.653.598-4.185.924-8.479.924-12.827 0-40.709-25.739-75.44-61.795-88.755a13.934 13.934 0 0 1-7.385-6.468l9.829 21.251c11.295 24.458 16.616 51.308 15.421 78.266l-1.194 26.85 16.887-.707c16.345-.815 28.4-2.011 32.31-3.587 1.466-.653 2.226-2.011 2.335-3.37z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#332345",
        fillRule: "nonzero",
      }}
      d="M22.372 131.747c0 2.011.163 4.077.434 6.142l1.032 7.229h31.658c3.746 0 7.276 2.01 9.177 5.272a10.666 10.666 0 0 0 9.176 5.272h67.442c6.897 0 13.304 3.75 16.67 9.783l21.938-6.848c1.412-7.229 2.498-19.24 2.498-26.795l-160.025-.055z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#ee815a",
        fillRule: "nonzero",
      }}
      d="M138.033 27.556c11.132 5.924 15.802 16.034 18.68 27.339.977 3.859 2.824 7.065 7.33 5.978 4.725-1.141 4.127-5 3.313-8.696-3.367-14.838-14.824-25.001-29.323-24.62zM165.401 200.882c13.087 0 23.73-10.816 23.73-24.132s-10.643-24.132-23.73-24.132c-13.086 0-23.73 10.816-23.73 24.132s10.644 24.132 23.73 24.132z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#f6926e",
        fillRule: "nonzero",
      }}
      d="M170.994 175.5c5.159-1.793 5.593-9.13.543-11.25-2.552-1.088-5.43-1.25-7.548-.272-5.267 2.065-6.733 5.49-6.733 11.577 0 3.641 1.357 6.902 3.53 9.457 0-.326-.055-.653-.055-.979-.054-3.75 5.485-6.848 10.263-8.533z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#f9c838",
        fillRule: "nonzero",
      }}
      d="M6.95 118.323c-.271-2.011-.488-4.022-.597-6.033a91.961 91.961 0 0 1-.271-6.74c0-19.132 5.647-36.904 15.367-51.742a86.429 86.429 0 0 1 5.213-7.175c.76-.924 1.466-1.848 2.28-2.717.38-.435.76-.87 1.14-1.359.761-.87 1.576-1.74 2.39-2.609a91.534 91.534 0 0 1 10.534-9.457c.924-.707 1.901-1.413 2.824-2.12 6.733-4.783 14.118-8.75 22.046-11.63.706-.273 1.358-.544 2.01-.925.488-.272.977-.598 1.466-.978l.651-.49a13.878 13.878 0 0 0 3.964-5.489c.543-1.304 1.25-2.5 2.118-3.533.652-.815 1.357-1.521 2.172-2.12a13.07 13.07 0 0 1 2.606-1.63 19.787 19.787 0 0 1 1.901-.76c.652-.218 1.303-.381 2.01-.49a13.2 13.2 0 0 1 2.063-.163h23.675c1.412 0 2.77.217 4.072.652.652.218 1.304.435 1.901.761a12.86 12.86 0 0 1 4.724 3.805 11.48 11.48 0 0 1 1.14 1.685c.326.598.652 1.25.924 1.902.923 2.283 2.389 4.24 4.29 5.707.76.598 1.574 1.087 2.443 1.522.434.217.869.38 1.303.598 7.33 2.717 14.227 6.25 20.58 10.598.489.326.978.652 1.466 1.033a95.525 95.525 0 0 1 16.942 15.49c2.715 3.152 5.159 6.468 7.44 9.892a91.301 91.301 0 0 1 1.9 2.989 78.723 78.723 0 0 1 2.607 4.674c6.95 13.153 10.86 28.154 10.86 44.08 0 4.347-.326 8.641-.923 12.826 1.303.598 2.552 1.359 3.584 2.337 2.334 2.12 3.855 5.055 3.855 8.316l-.054.054c0 .326-.055.652-.163.978-.326.979-1.086 1.848-2.281 2.338-16.507 6.63-180.605 6.63-197.113 0-4.127-1.522-1.52-12.719 4.942-14.077z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#292c33",
        fillRule: "nonzero",
      }}
      d="M79.931 43.916 59.568 76.907l20.363 11.794 20.363-11.794z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#292c33",
        fillRule: "nonzero",
      }}
      d="M79.931 96.908 59.568 84.353l20.363 28.86 20.363-28.86z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#f9aa36",
        fillRule: "nonzero",
      }}
      d="M201.566 129.03h.108c0-4.729-3.204-8.805-7.44-10.653.598-4.185.924-8.479.924-12.827 0-40.709-25.739-75.44-61.795-88.755a13.934 13.934 0 0 1-7.385-6.468l9.829 21.251c11.295 24.458 16.616 51.308 15.421 78.266l-1.194 26.85 16.887-.707c16.345-.815 28.4-2.011 32.31-3.587 1.466-.653 2.226-2.011 2.335-3.37z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      d="M165.59 252.846c2.474 4.052 3.926 8.707 4.41 13.415-.108.055-.161.11-.269.165-3.604 2.957-7.96 5.366-12.694 5.694-3.12.22-6.455.438-9.629.22.215-8.323-9.09-14.73-17.481-12.978-1.13-5.092-3.927-25.132-3.927-25.132s28.186.054 39.59 18.616z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#ee815a",
        fillRule: "nonzero",
      }}
      d="M147.318 271.09c7.907.164 15.707-1.534 22.538-5.697 1.291 11.558-6.293 24.704-15.384 30.073-6.293 3.724-17.805-2.849-19.472-8.272 9.252-5.149 12.533-11.01 12.318-16.104z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#f6926e",
        fillRule: "nonzero",
      }}
      d="M135 286.932c9.225-5.085 12.55-10.873 12.282-15.904 6.436.162 12.872-.974 18.718-3.624-.054 2.001.644 13.902-12.282 25.803-.697.65-2.52 1.136-2.628 1.894-.697.757-.375 2.326.965 2.326h.483c0 .487-9.654 5.247-15.178 6.167a56.364 56.364 0 0 0-2.36-16.662z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#ee815a",
        fillRule: "nonzero",
      }}
      d="M139.443 283.593c4.221.66 8.442 2.527 11.202 5.877 1.461 1.813 2.706 3.57 3.301 5.768.271.933-.324 5.547-5.52 7.964.92 2.252-.054 4.998-1.785 6.7-7.036 6.647-19.59-.713-20.727-2.141-2.598-3.296-3.356-7.965-3.897-11.81-.487-3.13 9.417-13.621 17.426-12.358z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      style={{
        fill: "#f6926e",
        fillRule: "nonzero",
      }}
      d="M145.787 301.985c-1.156 1.094-2.588 2.024-3.304 3.392-2.918.602-7.048.11-13.27-2.735-2.808-1.259-5.506-3.94-7.213-6.73 1.597-5.581 10.572-13.46 17.785-12.311 4.295.656 8.59 2.517 11.398 5.854.66.821 1.321 1.587 1.817 2.463-.55.273-.881.82-.66 1.532 1.21 3.665-1.102 6.292-4.24 7.605-1.102.11-2.203.82-2.313.93z"
      transform="matrix(.11082 0 0 .11082 113.238 109.163)"
    />
    <path
      d="m118.517 177.46-29.452 58.22-23.727-61.139Z"
      style={{
        fill: "#ffcd09",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: ".17843455px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeOpacity: 1,
      }}
      transform="rotate(40.242 194.677 112.918)"
    />
    <path
      d="m64.517 145.614 26.326 29.968-49.07 45.056zM117.897 147.187 92.82 178.664l45.419 41.913z"
      style={{
        fill: "#707070",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: ".17843455px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeOpacity: 1,
      }}
      transform="rotate(40.242 194.677 112.918)"
    />
    <path
      style={{
        fill: "#343434",
      }}
      d="m127.961 0-2.795 9.5v275.668l2.795 2.79 127.962-75.638z"
      transform="scale(.29508) rotate(40.242 274.662 515.509)"
    />
    <path
      style={{
        fill: "#8c8c8c",
      }}
      d="M127.962 0 0 212.32l127.962 75.639V154.158Z"
      transform="scale(.29508) rotate(40.242 274.662 515.509)"
    />
    <path
      style={{
        fill: "#3c3c3b",
      }}
      d="m127.961 312.187-1.575 1.92v98.199l1.575 4.6L256 236.587Z"
      transform="scale(.29508) rotate(40.242 274.662 515.509)"
    />
    <path
      style={{
        fill: "#8c8c8c",
      }}
      d="M127.962 416.905v-104.72L0 236.585Z"
      transform="scale(.29508) rotate(40.242 274.662 515.509)"
    />
    <path
      style={{
        fill: "#141414",
      }}
      d="m127.961 287.958 127.96-75.637-127.96-58.162z"
      transform="scale(.29508) rotate(40.242 274.662 515.509)"
    />
    <path
      style={{
        fill: "#393939",
      }}
      d="m0 212.32 127.96 75.638v-133.8z"
      transform="scale(.29508) rotate(40.242 274.662 515.509)"
    />
    <g transform="rotate(40.242 216.33 134.998) scale(1.07505)">
      <circle
        style={{
          opacity: 1,
          fill: "#010",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 2.09799981,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
          paintOrder: "normal",
          filter: "url(#a)",
        }}
        cx={-21.071}
        cy={139.902}
        r={10.29}
        transform="rotate(-40.242)"
      />
      <g
        transform="rotate(-40.244 194.11 -28.788) scale(.06446)"
        style={{
          fill: "none",
          fillRule: "evenodd",
          stroke: "none",
          strokeWidth: 1,
        }}
        clipPath="url(#b)"
      >
        <path
          style={{
            fill: "#332345",
            fillRule: "nonzero",
          }}
          d="M147 269.449H48.364C32.14 269.449 19 256.216 19 239.88V117.632h128z"
          transform="translate(0 8)"
        />
        <g
          style={{
            fillRule: "nonzero",
          }}
        >
          <path
            style={{
              fill: "#fff",
            }}
            d="M132.641 49.956c7.831-.383 14.528-4.104 20.955-8.317-3.619-14.883-18.687-26.702-32.188-24.896.864 2.681 4.05 24.841 4.05 24.841s7.4 2.408 7.183 8.372z"
            transform="translate(27 219.135)"
          />
          <path
            style={{
              fill: "#ee815a",
            }}
            d="M132.641 49.682c.054 2.408-1.188 5.417-4.644 9.138-.865.93-2.053 14.335-1.027 19.643 25.276-10.834 30.244-24.24 26.41-37.81-6.103 4.597-13.232 7.825-20.739 9.03z"
            transform="translate(27 219.135)"
          />
          <path
            style={{
              fill: "#f6926e",
            }}
            d="M139.824 70.146c2.754-2.08 5.13-4.487 7.399-7.059 3.618-4.705 6.103-9.903 7.129-15.703a26.166 26.166 0 0 0-1.08-6.785c-6.049 4.651-13.178 7.88-20.685 9.028.054 2.408-1.188 5.417-4.644 9.138-.81.82-2.43 14.773-1.89 21.12 4.158-3.447 8.91-5.909 13.231-9.028.162-.273.324-.492.54-.711z"
            transform="translate(27 219.135)"
          />
          <path
            style={{
              fill: "#ee815a",
            }}
            d="M137.61 80.76c2.808-3.939 4.266-8.973 1.35-15.374-2.97-6.566-8.48-8.098-14.258-4.76-2.862 1.64-5.13 4.158-7.345 6.675-2.052 2.407-4.158 5.198-4.05 8.371.108 4.05 1.674 7.442 4.914 9.794 1.782 1.313 7.67 4.487 10.586 4.432 2.106 0 4.212-.273 6.048-1.368 1.35-.766 2.593-1.805 3.295-3.228.702-1.422.702-3.228-.324-4.432-.108 0-.162-.055-.216-.11z"
            transform="translate(27 219.135)"
          />
          <path
            style={{
              fill: "#f6926e",
            }}
            d="M136.53 83.004c0 .055-.054.055-.054.11-.27.328-.594.492-.919.492-1.026.492-2.106.766-3.456.875-1.404.055-1.836-1.915-.702-2.571 1.836-1.095 3.618-2.462 5.13-4.05.055-.109.055-.273.163-.382.81-1.532 1.62-3.064 2.268-4.596.432-1.368.81-2.736 1.134-4.159-.27-1.04-.594-2.134-1.134-3.283-2.97-6.566-8.48-8.098-14.258-4.76-2.862 1.641-5.13 4.158-7.345 6.675-2.052 2.408-4.158 5.198-4.05 8.372.108 4.049 1.674 7.441 4.914 9.794 1.782 1.313 7.237 4.268 10.586 4.432 2.106.11 4.212-.274 6.048-1.368 1.35-.766 2.593-1.806 3.295-3.228.54-1.15.648-2.517.162-3.666-.594.492-1.188.93-1.782 1.313z"
            transform="translate(27 219.135)"
          />
          <path
            style={{
              fill: "#292c33",
            }}
            d="M26.95 153.478s.864-58.82 7.506-77.642c2.323-2.079 88.842 4.104 88.842 4.104l1.134 73.538h9.451v7.277h-27.057l-5.563-3.61v3.61H89.598s-1.189-42.733-9.938-62.157c-7.345 8.645-15.014 40.162-17.606 54.88-.81 4.487-1.566 7.277-1.566 7.277H48.12V157.2l-5.617 3.556H16.85v-7.277z"
            transform="translate(27 219.135)"
          />
          <path
            style={{
              fill: "#3e404d",
            }}
            d="M26.95 153.478h35.266c5.509-34.909 17.444-54.88 17.444-54.88s8.21 29.218 8.75 54.88h36.076c3.673-49.408-1.188-73.538-1.188-73.538l-88.842-4.104Z"
            transform="translate(27 219.135)"
          />
          <path
            style={{
              fill: "#292c33",
            }}
            d="M79.66 98.598c6.481 21.996 8.75 54.88 8.75 54.88h11.935c-.054 0-8.425-40.325-20.685-54.88z"
            transform="translate(27 219.135)"
          />
          <path
            style={{
              fill: "#010101",
            }}
            d="M99.697 153.478H88.409l1.189 7.277h11.665v-3.064z"
            transform="translate(27 219.135)"
          />
          <path
            style={{
              fill: "#292c33",
            }}
            d="m26.95 153.478 19.658-77.642H34.456l-7.507 77.642"
            transform="translate(27 219.135)"
          />
          <path
            style={{
              fill: "#17181c",
            }}
            d="M99.697 153.478H88.409l1.189 7.277h11.665v-3.064zM26.95 153.478h-10.1v7.277h12.152v-7.277h-2.053"
            transform="translate(27 219.135)"
          />
          <path
            style={{
              fill: "#fff",
            }}
            d="M103.531 1.75C56.6 4.05 37.751 16.689 37.751 16.689s-2.592 8.208-3.565 22.05c.594 0 1.135.165 1.62.493 2.107 1.587 2.43 4.268 1.999 8.153-.594 5.253-2.592 11.71-3.943 15.43.054 5.69.108 11.49 0 17.235h91.056l.216-47.11c.054 0-6.75-31.516-21.603-31.188z"
            transform="translate(27 219.135)"
          />
          <path
            style={{
              fill: "#ee815a",
            }}
            d="m33.916 79.995 3.727-15.65 5.833-2.516c10.207-4.377 15.068-16.196 10.963-26.647L42.071 3.557l17.877-1.97 31.108 35.292 12.421-29.985c11.828 0 21.441 9.74 21.441 21.722V79.94l-91.002.055z"
            transform="translate(27 219.135)"
          />
          <path
            style={{
              fill: "#fff",
            }}
            d="m84.629 60.625-37.21-58.82h8.802l37.805 58.82 15.932-58.82h8.857l-15.986 58.82h22.143v8.81h-90.57l3.835-8.81z"
            transform="translate(27 219.135)"
          />
          <path
            style={{
              fill: "#f9aa36",
            }}
            d="M33.916 79.995V57.178s34.997-8.754 10.478-43.827c3.24-2.463 15.608-11.764 15.608-11.764L91.11 36.879l10.91-29.602c11.827 0 23.492 9.357 23.492 21.34V79.94l-91.596.055z"
            transform="translate(27 219.135)"
          />
          <g
            style={{
              fill: "#292c33",
            }}
          >
            <path d="M60.754 279.87h91.812v9.302H60.754z" />
            <path d="M112.871 283.536c-14.15-34.909-42.071-56.412-42.341-56.631l5.508-7.442c1.242.93 30.244 23.31 45.366 60.516Z" />
            <path d="m126.805 282.441-8.533-1.367c4.59-29.711 17.768-53.786 18.308-54.771l7.561 4.268c-.216.383-13.07 23.801-17.336 51.87Z" />
          </g>
          <path
            style={{
              fill: "#fff",
            }}
            d="M4.645 31.9C2.16 35.947.702 40.6.216 45.304c.108.055.162.11.27.164 3.619 2.955 7.993 5.362 12.746 5.69 3.132.22 6.48.438 9.667.22-.216-8.318 9.127-14.72 17.552-12.968 1.134-5.089 3.943-25.115 3.943-25.115s-28.3.055-39.75 18.603z"
            transform="translate(27 219.135)"
          />
          <path
            style={{
              fill: "#ee815a",
            }}
            d="M22.845 50.612c-7.94.164-15.77-1.532-22.629-5.69C-1.08 56.467 6.535 69.599 15.662 74.96c6.319 3.72 17.876-2.845 19.55-8.262C25.924 61.555 22.63 55.7 22.846 50.612Z"
            transform="translate(27 219.135)"
          />
          <path
            style={{
              fill: "#f6926e",
            }}
            d="M35.213 66.699c-9.29-5.144-12.638-10.998-12.368-16.087-6.48.164-12.962-.985-18.848-3.666.054 2.025-.649 14.062 12.367 26.1.702.656 2.538 1.149 2.646 1.915.703.766.379 2.353-.972 2.353h-.486c0 .492 9.722 5.307 15.284 6.237-.054-5.69.702-11.435 2.377-16.852z"
            transform="translate(27 219.135)"
          />
          <path
            style={{
              fill: "#ee815a",
            }}
            d="M30.136 63.361c-4.213.657-8.425 2.517-11.18 5.855-1.458 1.805-2.7 3.556-3.294 5.745-.27.93.324 5.526 5.509 7.934-.918 2.243.054 4.979 1.782 6.675 7.02 6.62 19.55-.711 20.685-2.134 2.592-3.283 3.348-7.934 3.888-11.764.486-3.119-9.451-13.57-17.39-12.31z"
            transform="translate(27 219.135)"
          />
          <path
            style={{
              fill: "#f6926e",
            }}
            d="M24.25 81.8c1.133 1.095 2.537 2.025 3.24 3.393 2.862.602 6.912.11 13.015-2.736 2.755-1.259 5.4-3.94 7.075-6.73-1.566-5.581-10.37-13.46-17.444-12.311-4.213.656-8.425 2.517-11.18 5.854-.648.821-1.296 1.587-1.782 2.463.54.273.864.82.648 1.532-1.188 3.666 1.08 6.292 4.159 7.605 1.08.11 2.16.82 2.268.93z"
            transform="translate(27 219.135)"
          />
        </g>
        <path
          style={{
            fill: "#ee815a",
            fillRule: "nonzero",
          }}
          d="M164 193.038c11.588 0 21-9.705 21-21.616s-9.412-21.617-21-21.617-21 9.65-21 21.617c0 11.966 9.412 21.616 21 21.616z"
          transform="translate(0 8)"
        />
        <path
          style={{
            fill: "#ee815a",
            fillRule: "nonzero",
          }}
          d="M174 166.93c0 43.015-30.673 68.335-68.5 68.335S37 209.945 37 166.929s30.673-87.502 68.5-87.502 68.5 44.432 68.5 87.502z"
          transform="translate(0 8)"
        />
        <path
          style={{
            fill: "#f6926e",
            fillRule: "nonzero",
          }}
          d="M170 170.919H84.972c-11.597 0-20.972 9.5-20.972 21.253v31.358c11.597 8.292 26.12 12.74 41.89 12.74 29.319 0 54.355-15.377 64.11-42.506z"
          transform="translate(0 8)"
        />
        <path
          style={{
            fill: "#332345",
            fillRule: "nonzero",
          }}
          d="M94.012 160.865H18v-54.292l109 21.083c-.054 18.352-14.812 33.209-32.988 33.209z"
          transform="translate(0 8)"
        />
        <path
          style={{
            fill: "#332345",
            fillRule: "nonzero",
          }}
          d="M146.195 160.865H122V124.67h60c.054 19.962-16.004 36.195-35.805 36.195z"
          transform="translate(0 8)"
        />
        <path
          style={{
            fill: "#f9c838",
            fillRule: "nonzero",
          }}
          d="M.054 129.413c.055 1.365.815 2.73 2.443 3.331 16.504 6.662 180.566 6.662 197.07 0 2.388-.983 2.931-3.494 2.008-5.351-.705-3.986-3.311-7.262-6.894-8.791.597-4.205.868-8.464.868-12.833 0-40.953-25.733-75.846-61.78-89.17a13.932 13.932 0 0 1-8.035-7.862C123.616 3.44 118.567 0 112.867 0h-23.67c-5.646 0-10.75 3.495-12.812 8.791-1.466 3.659-4.343 6.498-8.035 7.863-36.102 13.379-61.835 48.271-61.835 89.17 0 4.368.325 8.682.923 12.887C3.203 120.567 0 124.663 0 129.413Z"
          transform="translate(0 8)"
        />
        <path
          style={{
            fill: "#292c33",
            fillRule: "nonzero",
          }}
          d="M113.473 89.481 134 77.566l-20.527-33.328L93 77.566z"
          transform="translate(0 8)"
        />
        <path
          style={{
            fill: "#292c33",
            fillRule: "nonzero",
          }}
          d="M113.473 113.61 134 84.455l-20.527 12.684L93 84.454z"
          transform="translate(0 8)"
        />
        <path
          style={{
            fill: "#f9aa36",
            fillRule: "nonzero",
          }}
          d="M2.421 132.429c3.82 1.58 15.764 2.78 32.012 3.598l16.733.708-1.184-26.928a171.2 171.2 0 0 1 15.28-78.494L75 10.054c-1.56 2.998-4.143 5.342-7.317 6.487C31.958 29.896 6.456 64.728 6.456 105.555c0 4.361.323 8.668.915 12.865C3.174 120.273 0 124.36 0 129.104h.108c0 1.362.753 2.725 2.313 3.325z"
          transform="translate(0 8)"
        />
        <path
          style={{
            fill: "#ee815a",
            fillRule: "nonzero",
          }}
          d="M43 193.038c11.588 0 21-9.705 21-21.616s-9.412-21.617-21-21.617-21 9.65-21 21.617c0 11.966 9.412 21.616 21 21.616z"
          transform="translate(0 8)"
        />
        <path
          style={{
            fill: "#f6926e",
            fillRule: "nonzero",
          }}
          d="M46.991 178.076c0 .332 0 .61-.054.886A13.389 13.389 0 0 0 50 170.43c0-5.485-3.278-10.195-7.899-12.079-1.988-.83-4.244-.609-6.125.5 0 0-.054 0-.054.055a7.68 7.68 0 0 0-3.546 5.097c-.269 1.274-.376 2.548-.376 3.878 0 .499 0 .942.054 1.44a9.293 9.293 0 0 1 5.158-1.55c5.373 0 9.779 4.598 9.779 10.305z"
          transform="translate(0 8)"
        />
        <path
          style={{
            fill: "#292c33",
            fillRule: "nonzero",
          }}
          d="M10.034.11C4.664.11.27 4.581.27 10.046v7.728c0 5.465 4.393 9.936 9.763 9.936s9.763-4.471 9.763-9.936v-7.728c0-5.52-4.34-9.936-9.763-9.936zM53.966.11c-5.37 0-9.763 4.471-9.763 9.936v7.728c0 5.465 4.394 9.936 9.763 9.936 5.37 0 9.763-4.471 9.763-9.936v-7.728c0-5.52-4.34-9.936-9.763-9.936z"
          transform="translate(82 176.908)"
        />
        <path d="M96.807 190.708c2.007 0 3.634-1.656 3.634-3.754 0-2.097-1.627-3.753-3.634-3.753-2.007 0-3.634 1.656-3.634 3.753 0 2.098 1.627 3.754 3.634 3.754zM141.607 190.708c2.007 0 3.634-1.656 3.634-3.754 0-2.097-1.627-3.753-3.634-3.753-2.007 0-3.634 1.656-3.634 3.753 0 2.098 1.627 3.754 3.634 3.754z" />
        <path
          style={{
            fill: "#fff",
            fillRule: "nonzero",
          }}
          d="M113.973 218.173c7.2 0 13.027-5.846 13.027-13.07h-26c0 7.28 5.827 13.07 12.973 13.07z"
          transform="translate(0 8)"
        />
      </g>
    </g>
  </svg>
);

export default SvgComponent;
